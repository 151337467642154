<template>
  <v-container>
    <v-row>
      <v-col>
        <DataTableSSR
          :api-end-point="`/rooms`"
          :headers="headers"
          :instant-load="true"
        >
          <template #table-top-right>
            <v-dialog v-model="dialogNewRoom" persistent width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on" class="pa-2"
                  >New Room
                </v-btn>
              </template>
              <NewRoom @closeDialog="closeDialogNewRoom"> </NewRoom>
            </v-dialog>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn icon link :to="`/organization/room/${item.id}`">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </DataTableSSR>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const DataTableSSR = () => import("@/components/global/DataTableSSR.vue");
const NewRoom = () => import("@/components/room/NewRoom.vue");
export default {
  components: { DataTableSSR, NewRoom },
  data: () => ({
    dialogNewRoom: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Shift",
        value: "shift",
      },
      {
        text: "Section",
        value: "section",
      },
      {
        text: "Session",
        value: "session",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
      },
    ],
  }),

  mounted() {},
  methods: {
    closeDialogNewRoom() {
      this.dialogNewRoom = false;
    },
  },
};
</script>

<style>
</style>